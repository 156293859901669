import React from 'react';
import { Typography, ButtonBase } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import GoogleLogin from 'react-google-login';
import GoogleButton from '../../img/btn_google_signin_dark_normal_web@2x.png';
import { setAuthTokenHeader } from '../../services/api';
import { getToken, isExpired, parseJwt } from '../../services/auth';
import logo from './../../img/ww-pestpac.png';
import useMount from '../../hooks/useMount';

const noop = () => undefined;

const styles = () => ({
	logo: {
		width: '300px',
		padding: '10px',
	},
});

const Login = ({ classes, requestToken, updateGoogleUser, authError }) => {
	useMount(() => {
		const token = getToken();

		if (token && !isExpired(token)) {
			const parsedJwt = parseJwt(token);
			setAuthTokenHeader(token);
			updateGoogleUser(parsedJwt);
		}
	});

	const handleSuccess = (response) => {
		if (response) {
			const { tokenId } = response;
			requestToken(tokenId, true);
		}
	};

	return (
		<>
			<img src={logo} className={classes.logo} alt="Logo" />
			<Typography variant="h4">Administration</Typography>
			<GoogleLogin
				buttonText="Login"
				clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
				cookiePolicy={'single_host_origin'}
				onSuccess={handleSuccess}
				render={({ onClick }) => (
					<ButtonBase onClick={onClick}>
						<img
							style={{
								cursor: 'pointer',
								height: '46px',
								width: '191px',
								marginTop: '15px',
							}}
							src={GoogleButton}
							alt="Log In"
						/>
					</ButtonBase>
				)}
				onFailure={noop}
			/>
			<Typography variant="overline" color="secondary">
				{authError}
			</Typography>
		</>
	);
};

export default withStyles(styles)(Login);
