import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loadSqlInstances, updateSqlInstance } from './../../store/actions/index';
import SqlInstanceList from './SqlInstanceList';

const mapStateToProps = (state) => ({
	instances: state.sqlInstances,
	envs: state.envs,
});

const mapDispatchToProps = {
	loadSqlInstances,
	updateSqlInstance,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SqlInstanceList));
