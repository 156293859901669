import axios from 'axios';
import apiClient, { BASE_URL, setAuthTokenHeader } from '../../services/api';
import { parseJwt, removeToken, setToken } from '../../services/auth';
import {
	ENVS_LOADED,
	FATAL_ERROR,
	GOOGLE_USER_UPDATED,
	LOGOUT,
	LOGS_LOADED,
	MESSAGE_LOADED,
	PENDING_ACTION_COMPLETED,
	PENDING_ACTION_STARTED,
	PORTALADMINS_LOADED,
	PORTALURLS_LOADED,
	REQUEST_TOKEN_FAIL,
	REQUEST_TOKEN_SUCCESS,
	ROUTEOP_DETAIL_LOADED,
	SQLINSTANCES_LOADED,
	SQLINSTANCE_UPDATED,
	TENANTS_LOADED,
	TENANT_DELETED,
	TENANT_DETAIL_CLEARED,
	TENANT_DETAIL_LOADED,
	TENANT_SEARCH_SET,
	TENANT_UPDATED,
	URLS_LOADED,
	URL_DELETED,
	URL_UPDATED,
	USERS_LOADED,
	USER_DELETED,
	USER_UPDATED,
	BRANDS_LOADED,
	COMPANYKEY_LOADED,
} from '../types';

export const fatalErrorOccured = (e) => (dispatch) => {
	dispatch({
		type: FATAL_ERROR,
		payload: e.response && e.response.data ? e.response.data.error : 'Unknown Error',
	});
};

export const pendingActionCompleted = () => (dispatch) => {
	dispatch({
		type: PENDING_ACTION_COMPLETED,
		payload: null,
	});
};

export const pendingActionStarted = () => (dispatch) => {
	dispatch({
		type: PENDING_ACTION_STARTED,
		payload: null,
	});
};

export const searchLogs = (search) => async (dispatch) => {
	try {
		const { data: logs } = await apiClient.post('/logs', search);
		dispatch({ type: LOGS_LOADED, payload: logs });
	} catch (e) {
		dispatch({ type: LOGS_LOADED, payload: ['Search Timed Out'] });
	}
};

export const loadEnvironments = () => async (dispatch) => {
	try {
		const { data: envs } = await apiClient.get('/environments');
		dispatch({ type: ENVS_LOADED, payload: envs });
	} catch (e) {
		// ...
	}
};

export const loadMessage = () => async (dispatch) => {
	const { data: message } = await apiClient.get('/systemMessage');
	dispatch({ type: MESSAGE_LOADED, payload: message[0] });
	return message[0];
};

export const updateMessage = (message) => async (dispatch) => {
	const messageToSave = { Message: message, Id: '1' };
	const { data: updatedMessage } = await apiClient.post('/systemMessage', messageToSave);
	dispatch({ type: MESSAGE_LOADED, payload: updatedMessage });
};

export const loadUsers = () => async (dispatch) => {
	const { data: users } = await apiClient.get('/users');
	dispatch({ type: USERS_LOADED, payload: users });
};

export const updateUser = (user) => async (dispatch) => {
	const { data: updatedUser } = await apiClient.post('/users', user);
	dispatch({ type: USER_UPDATED, payload: updatedUser });
};

export const deleteUser = (email) => async (dispatch) => {
	await apiClient.delete(`/users/${email}`);
	dispatch({ type: USER_DELETED, payload: email });
};

export const loadPortalUrls = () => async (dispatch) => {
	const { data: urls } = await apiClient.get('/portalUrls');
	dispatch({ type: PORTALURLS_LOADED, payload: urls.filter((u) => u.companyKey.length === 6) });
};

export const loadPortalAdmins = (companyKey) => async (dispatch) => {
	const { data: admins } = await apiClient.get(`/portalAdmins/${companyKey}`);
	dispatch({
		type: PORTALADMINS_LOADED,
		payload: { companyKey, admins },
	});
};

export const getUniqueCompanyKey = () => async (dispatch) => {
	const { data: companyKey } = await apiClient.get('/uniqueCompanyKey');
	if (companyKey) dispatch({ type: COMPANYKEY_LOADED, payload: companyKey });
};

export const loadUrls = () => async (dispatch) => {
	const { data: urls } = await apiClient.get('/salesAssistantUrls');
	dispatch({ type: URLS_LOADED, payload: urls });
};

export const updateUrl = (url) => async (dispatch) => {
	url.Url = url.Url.toLowerCase(); // SA matches on *lowercase* host header
	const { data: updatedUrl } = await apiClient.post('/salesAssistantUrls', url);
	dispatch({ type: URL_UPDATED, payload: updatedUrl });
};

export const deleteUrl = (url) => async (dispatch) => {
	await apiClient.delete(`/salesAssistantUrls/${url}`);
	dispatch({ type: URL_DELETED, payload: url });
};

export const loadSqlInstances = (env) => async (dispatch) => {
	const { data: instances } = await apiClient.get(`/sqlInstances/${env}`);
	dispatch({ type: SQLINSTANCES_LOADED, payload: instances });
};

export const updateSqlInstance = (instance, env) => async (dispatch) => {
	const url = `/sqlInstances/${env}/${instance.sqlInstanceId}`;
	const { data: updatedInstance } = await apiClient.put(url, instance);
	dispatch({ type: SQLINSTANCE_UPDATED, payload: updatedInstance });
};

export const loadTenants = () => async (dispatch) => {
	const { data: tenants } = await apiClient.get('/tenantsAll');
	const { data: config } = await apiClient.get('/configAll');
	const { data: info } = await apiClient.get('/tenantsInfoAll');
	//join all lists of company information
	const allTenants = tenants.map((ten) => {
		return {
			...ten,
			DatabaseName:
				info[ten.CompanyKey] !== undefined ? info[ten.CompanyKey].DatabaseName : '',
			SqlInstance: info[ten.CompanyKey] !== undefined ? info[ten.CompanyKey].SqlInstance : '',
			EnvironmentName:
				info[ten.CompanyKey] !== undefined ? info[ten.CompanyKey].EnvironmentName : '',
			Users: config[ten.CompanyKey] !== undefined ? config[ten.CompanyKey].Users : '',
			MobileUsers:
				config[ten.CompanyKey] !== undefined ? config[ten.CompanyKey].MobileUsers : '',
		};
	});

	dispatch({ type: TENANTS_LOADED, payload: allTenants });
};

export const getBrandings = (environment) => async (dispatch) => {
	const { data: brands } = await apiClient.get(`/branding/${environment}`);
	dispatch({ type: BRANDS_LOADED, payload: brands });
	return brands;
};

export const loadTenant = (companyKey) => async (dispatch) => {
	const { data: tenant } = await apiClient.get(`/tenants/${companyKey}`);
	const { data: config } = await apiClient.get(`/tenants/${companyKey}/config`);
	dispatch({ type: TENANT_DETAIL_LOADED, payload: { ...tenant, config } });
};

export const clearTenantDetail = () => async (dispatch) => {
	dispatch({ type: TENANT_DETAIL_CLEARED, payload: null });
};

export const setSearchTerm = (searchTerm) => async (dispatch) => {
	dispatch({ type: TENANT_SEARCH_SET, payload: searchTerm });
};

export const loadRouteOp = (companyKey) => async (dispatch) => {
	const { data = [] } = await apiClient.get(`/routeOp/${companyKey}`);
	const payload = data.filter(({ deleted }) => !deleted)[0] || null;
	dispatch({ type: ROUTEOP_DETAIL_LOADED, payload });
};

export const createRouteOp = (companyKey) => async (dispatch) => {
	const req = { companyKey, vehicles: 1, waypoints: 2500 };
	const { data } = await apiClient.post('/routeOp', req);
	dispatch({ type: ROUTEOP_DETAIL_LOADED, payload: data });
};

export const updateRouteOp = (token, companyKey, vehicles, waypoints) => async (dispatch) => {
	const req = { companyKey, vehicles, waypoints };
	const { data } = await apiClient.put(`/routeOp/${token}`, req);
	dispatch({ type: ROUTEOP_DETAIL_LOADED, payload: data && data.length === 1 ? data[0] : null });
};

export const createTenantDatabase = (tenantToCreate) => async (dispatch) => {
	tenantToCreate.IsTestDatabase = tenantToCreate.IsTestDatabase === 'true';
	tenantToCreate.Address2 = tenantToCreate.Address2 || '';
	const url = `/tenants/${tenantToCreate.Environment}`;
	const { data: newTenant } = await apiClient.post(url, tenantToCreate);
	dispatch({ type: TENANT_UPDATED, payload: newTenant });
};

export const createOrUpdateTenant = (tenant, xb) => async (dispatch) => {
	tenant.ForceUrl = tenant.ForceUrl ? 1 : 0;
	tenant.EnableCrossBrowser = xb;
	const { data: updatedTenant } = await apiClient.put('/tenants', tenant);
	dispatch({ type: TENANT_UPDATED, payload: updatedTenant });
};

export const updateTenantDetail = (tenantDetail) => async (dispatch) => {
	await apiClient.put(`/tenants/${tenantDetail.companyKey}/config`, tenantDetail);
	dispatch({ type: TENANT_DETAIL_LOADED, payload: tenantDetail });
};

export const deleteTenant = (tenantToDelete) => async (dispatch) => {
	await apiClient.delete(`/tenants/${tenantToDelete.CompanyKey}`);
	dispatch({ type: TENANT_DELETED, payload: tenantToDelete.CompanyKey });
};

export const updateGoogleUser = (user) => (dispatch) => {
	dispatch({
		type: GOOGLE_USER_UPDATED,
		payload: user,
	});
};

export const logout = () => (dispatch) => {
	dispatch({
		type: LOGOUT,
	});
};

export const requestToken = (token) => async (dispatch) => {
	try {
		const {
			data: { token: nextToken },
		} = await axios.post(`${BASE_URL}/token`, undefined, {
			headers: { Authorization: `Bearer ${token}` },
		});

		const parsedJwt = parseJwt(nextToken);

		setToken(nextToken);
		setAuthTokenHeader(nextToken);

		dispatch({ type: GOOGLE_USER_UPDATED, payload: parsedJwt });
		dispatch({ type: REQUEST_TOKEN_SUCCESS, payload: parsedJwt });
	} catch (e) {
		removeToken();
		dispatch({ type: LOGOUT });
		dispatch({ type: REQUEST_TOKEN_FAIL, payload: e.message });
	}
};
