import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	createOrUpdateTenant,
	createTenantDatabase,
	getBrandings,
	getUniqueCompanyKey,
} from '../../store/actions';
import AddTenantWrapper from './AddTenantWrapper';
import { getCompanyKeys } from '../../store/selectors/tenants';

const mapStateToProps = (state) => ({
	envs: state.envs,
	allKeys: getCompanyKeys(state),
	brands: state.brands,
	companyKey: state.companyKey,
});

const actions = {
	createOrUpdateTenant,
	createTenantDatabase,
	getBrandings,
	getUniqueCompanyKey,
};

export const viewMode = {
	CreateDatabase: 'CreateDatabase',
	CreateTenantOnly: 'CreateTenantOnly',
};

export default withRouter(connect(mapStateToProps, actions)(AddTenantWrapper));
