import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '../shared/Dialog';
import Toast from '../shared/Toast';
import SelectRole from './SelectRole';
import UserListTable from './UserListTable';
import useMount from '../../hooks/useMount';

const emptyUser = { Email: '', Role: 'ReadOnly' };

const UserList = ({ users, loadUsers, updateUser, deleteUser }) => {
	const [newUser, setNewUser] = useState(emptyUser);
	const [toast, setToast] = useState();
	const [dialogState, setDialogState] = useState();

	async function fetchUsers() {
		await loadUsers();
	}

	useMount(() => {
		fetchUsers();
	});

	const handleAddUser = async () => {
		if (users.some((u) => u.Email === newUser.Email)) {
			setDialogState({
				open: true,
				title: 'Duplicate User',
				body: `User '${newUser.Email}' already exists`,
				buttons: [{ label: 'OK', onClick: () => setDialogState({ open: false }) }],
			});
			return;
		}

		await handleUpdateUser(newUser);
		setToast(`Added ${newUser.Email}`);
		setNewUser(emptyUser);
	};

	const handleUpdateUser = async (user) => {
		await updateUser(user);
	};

	const handleDeleteUser = (email) => {
		setDialogState({
			open: true,
			title: 'Delete User',
			body: `Are you sure you want to delete '${email}'?`,
			buttons: [
				{ label: 'OK', primary: true, onClick: () => handleDeleteUserConfirmed(email) },
				{ label: 'Cancel', onClick: handleDeleteUserCancelled },
			],
		});
	};

	const handleDeleteUserConfirmed = async (email) => {
		setDialogState({ open: false });
		await deleteUser(email);
		setToast(`Deleted ${email}`);
	};

	const handleDeleteUserCancelled = () => {
		setDialogState({ open: false });
	};

	const handleChangeEmail = ({ target: { value: Email } }) => setNewUser({ ...newUser, Email });

	const handleChangeRole = ({ target: { value: Role } }) => setNewUser({ ...newUser, Role });

	if (!users) {
		return null;
	}

	return (
		<Paper square style={{ maxWidth: '1000px' }}>
			<Table>
				<TableBody>
					<TableRow>
						<TableCell>
							<TextField
								style={{ width: '300px' }}
								placeholder="Email Address of New User"
								value={newUser.Email}
								onChange={handleChangeEmail}
							/>
						</TableCell>
						<TableCell>
							<SelectRole value={newUser.Role} onChange={handleChangeRole} />
						</TableCell>
						<TableCell>
							<IconButton
								disabled={
									!(newUser.Email && newUser.Email.endsWith('@workwave.com'))
								}
								onClick={handleAddUser}
							>
								<AddIcon />
							</IconButton>
						</TableCell>
					</TableRow>
					<UserListTable
						users={users}
						onUpdateUser={handleUpdateUser}
						onDeleteUser={handleDeleteUser}
					/>
				</TableBody>
			</Table>
			<Dialog state={dialogState} />
			<Toast message={toast} onHide={() => setToast()} />
		</Paper>
	);
};

export default UserList;
