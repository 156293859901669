const map = [
	{
		value: 'ARM',
		text: 'ARM',
	},
	{
		value: 'Transworld',
		text: 'Transworld',
	},
	{
		value: 'C2C',
		text: 'Coast-to-Coast',
	},
];

export default map;
