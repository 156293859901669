import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { deleteUrl, loadUrls, updateUrl } from '../../store/actions';
import UrlList from './UrlList';

const mapStateToProps = (state) => ({
	urls: state.saUrls,
});

const mapDispatchToProps = {
	loadUrls,
	updateUrl,
	deleteUrl,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UrlList));
