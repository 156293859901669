import React from 'react';
import { Field } from 'react-final-form';
import { MenuItem, Select } from '@material-ui/core';
import TextField from '../shared/TextField';
import selectOptionsMap from '../../config/options';

const selectFields = Object.keys(selectOptionsMap);

const noop = () => undefined;
const required = (value) => (value || value === 0 || value === '0' ? undefined : 'Required');
const identity = (v) => v;
const toInt = (v) => parseInt(v);

const ConfigTextField = ({
	className: baseClass,
	configFieldName,
	displayName,
	fieldType,
	className,
	name,
}) => {
	return selectFields.includes(configFieldName) ? (
		<Field
			name={`${name}.value`}
			component={({ input: { value, onChange } }) => (
				<Select value={value} onChange={onChange}>
					{selectOptionsMap[configFieldName].map(({ text, value: optionValue }, idx) => (
						<MenuItem value={optionValue} key={`${name}.${idx}`}>
							{text}
						</MenuItem>
					))}
				</Select>
			)}
		/>
	) : (
		<Field
			name={`${name}.value`}
			component={TextField}
			label={displayName}
			className={className}
			type={fieldType === 'Numeric' || fieldType === 'Encrypted' ? 'number' : 'text'}
			validate={fieldType === 'Encrypted' ? required : noop}
			parse={fieldType === 'Numeric' || fieldType === 'Encrypted' ? toInt : identity}
		/>
	);
};

export default ConfigTextField;
