import Snackbar from '@material-ui/core/Snackbar';
import React from 'react';

const Dialog = ({ message = '', onHide }) => {
	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			open={!!message}
			onClose={onHide}
			autoHideDuration={3000}
			message={message}
		/>
	);
};

export default Dialog;
