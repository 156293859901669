import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import useMount from '../../hooks/useMount';
import '../../css/react-draft-wysiwyg.css';

const styles = (theme) => ({
	root: {
		padding: '10px 10px 10px 10px',
	},
	editor: {
		height: 500,
	},
	caption: {
		marginTop: '15px',
	},
	button: {
		float: 'right',
	},
});

const SystemMessage = ({ classes, message, loadMessage, updateMessage }) => {
	const [editorState, setEditorState] = useState();

	async function fetchMessage() {
		await loadMessage();
	}

	useMount(() => {
		fetchMessage();
	});

	useEffect(() => {
		if (message) {
			let html = message.Message;
			if (!html) html = '';

			const contentBlock = htmlToDraft(html);

			let initialEditorState;
			if (contentBlock) {
				const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
				initialEditorState = EditorState.createWithContent(contentState);
			} else {
				initialEditorState = EditorState.createEmpty();
			}

			setEditorState(initialEditorState);
		}
	}, [message]);

	const handleUpdateMessage = () => {
		const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
		updateMessage(html);
	};

	const onEditorStateChange = (newState) => {
		setEditorState(newState);
	};

	if (!editorState) {
		return null;
	}

	return (
		<Paper style={{ width: '800px' }}>
			<Grid container className={classes.root} spacing={16}>
				<Grid item xs={12} className={classes.editor}>
					<Editor
						editorState={editorState}
						wrapperClassName="demo-wrapper"
						editorClassName="demo-editor"
						onEditorStateChange={onEditorStateChange}
					/>
				</Grid>
				<Grid item xs={6} className={classes.caption}>
					<Typography variant="body1">
						Last Updated at {new Date(message.LastChanged).toLocaleString()}
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<IconButton className={classes.button} onClick={handleUpdateMessage}>
						<SaveIcon />
					</IconButton>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default withStyles(styles)(SystemMessage);
