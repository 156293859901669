import {
	Button,
	Dialog as MuiDialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from '@material-ui/core';
import React from 'react';

const Dialog = ({ state = {} }) => {
	return (
		<MuiDialog open={!!state.open} disableBackdropClick disableEscapeKeyDown maxWidth="xs">
			<DialogTitle>{state.title || ''}</DialogTitle>
			<DialogContent>
				<Typography>{state.body || ''}</Typography>
			</DialogContent>
			<DialogActions>
				{state.buttons &&
					state.buttons.map((b) => (
						<Button
							key={b.label}
							onClick={b.onClick}
							variant={b.primary ? 'contained' : 'text'}
							color="primary"
						>
							{b.label}
						</Button>
					))}
			</DialogActions>
		</MuiDialog>
	);
};

export default Dialog;
