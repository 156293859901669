import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	createOrUpdateTenant,
	deleteTenant,
	loadTenants,
	setSearchTerm,
} from '../../store/actions';
import TenantList from './TenantList';

const mapStateToProps = ({ tenants, search }) => ({
	tenants,
	search,
});

const actions = {
	deleteTenant,
	loadTenants,
	createOrUpdateTenant,
	setSearchTerm,
};

export default withRouter(connect(mapStateToProps, actions)(TenantList));
