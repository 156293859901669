import PropTypes from 'prop-types';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import BackDrop from './Backdrop';

const throb = keyframes`
	0% {
		box-shadow: 0px 0px 0px 0px rgba(105, 128, 149, 0.3), 0px 0px 0px 0px rgba(105, 128, 149, 0.3),
			0px 0px 0px 20px rgba(105, 128, 149, 0.3), 0px 0px 0px 40px rgba(105, 128, 149, 0.3);
	}

	100% {
		box-shadow: 0px 0px 0px 20px rgba(105, 128, 149, 0.3), 0px 0px 0px 20px rgba(105, 128, 149, 0.3),
			0px 0px 0px 40px rgba(105, 128, 149, 0.3), 0px 0px 0px 60px rgba(105, 128, 149, 0);
	}
`;

const LoaderWrapper = styled.div`
	padding: 0px;
	position: absolute;
	top: 50%;
	left: 50%;
	color: none;
	width: 5px;
	height: 5px;
	background-color: none;
	margin: 0px auto;
	border-radius: 10px;
	-webkit-animation: ${throb} 0.75s linear infinite;
	animation: ${throb} 0.75s linear infinite;
`;

const Loader = ({ show }) => {
	if (!show) {
		return null;
	}

	return (
		<BackDrop zIndex={6000}>
			<LoaderWrapper />
		</BackDrop>
	);
};

Loader.propTypes = {
	show: PropTypes.bool,
};

export default Loader;
