import {
	Fab,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	withStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import debounce from 'debounce';
import React, { useEffect, useState } from 'react';
import Dialog from '../shared/Dialog';
import Toast from '../shared/Toast';
import Tenant from './Tenant';

const styles = (theme) => ({
	fabAdd: {
		position: 'absolute',
		bottom: '16px',
		right: '16px',
	},
	fabRefresh: {
		position: 'absolute',
		bottom: '16px',
		right: '84px',
	},
	tableHead: {
		backgroundColor: '#fff',
		position: 'sticky',
		top: 0,
		zIndex: 1,
	},
	colSmall: {
		width: '6%',
		padding: '0 0 0 10px',
	},
	colMedium: {
		width: '11%',
		padding: '0 0 0 10px',
	},
	colLarge: {
		width: '15%',
		padding: '0 0 0 10px',
	},
	colStart: {
		width: '6%',
		padding: '0 0 0 10px',
	},
});

const matchesFilter = (tenant, filter) =>
	(typeof tenant.CompanyKey === 'string' &&
		tenant.CompanyKey.trim().toLowerCase().includes(filter)) ||
	(typeof tenant.CompanyName === 'string' &&
		tenant.CompanyName.trim().toLowerCase().includes(filter)) ||
	(typeof tenant.Url === 'string' && tenant.Url.trim().toLowerCase().includes(filter)) ||
	(typeof tenant.ApiUrl === 'string' && tenant.ApiUrl.trim().toLowerCase().includes(filter)) ||
	(typeof tenant.DatabaseName === 'string' &&
		tenant.DatabaseName.trim().toLowerCase().includes(filter)) ||
	(typeof tenant.EnvironmentName === 'string' &&
		tenant.EnvironmentName.trim().toLowerCase().includes(filter)) ||
	(typeof tenant.SqlInstance === 'string' &&
		tenant.SqlInstance.trim().toLowerCase().includes(filter)) ||
	(typeof tenant.Users === 'number' && tenant.Users.toString().includes(filter)) ||
	(typeof tenant.MobileUsers === 'number' && tenant.MobileUsers.toString().includes(filter));

const xbUrl = (url) =>
	url === 'classic.west.pestpac.com' ? 'app.west.pestpac.com' : 'app.pestpac.com';

const xbApiUrl = (url) =>
	url === 'classic.west.pestpac.com' ? 'api.west.pestpac.com' : 'api-east.pestpac.com';

const rowsPerPage = 10;

const TenantList = ({
	tenants,
	classes,
	loadTenants,
	createOrUpdateTenant,
	deleteTenant,
	history,
	search,
	setSearchTerm,
}) => {
	const [toast, setToast] = useState();
	const [dialogState, setDialogState] = useState();
	const [filteredTenants, setFilteredTenants] = useState();
	const [page, setPage] = useState(0);

	useEffect(() => {
		const query = search && search.trim().toLowerCase();

		if (query) {
			setFilteredTenants(tenants.filter((t) => matchesFilter(t, query)));
		} else {
			setFilteredTenants(tenants);
		}
	}, [tenants, search]);

	const handleEnableCrossBrowser = async (tenant) => {
		const updatedTenant = {
			...tenant,
			ForceUrl: true,
			Url: xbUrl(tenant.Url),
			ApiUrl: xbApiUrl(tenant.Url),
		};
		await handleUpdate(updatedTenant, true);
	};

	const handleUpdate = async (tenantToUpdate, forcingXB) => {
		if (forcingXB) {
			setDialogState({
				open: true,
				title: 'Force Cross-Browser',
				body: 'Do you want to create In-App Messaging and enable PDF Forms?',
				buttons: [
					{
						label: 'Yes',
						primary: true,
						onClick: () => handleUpdateConfirmed(tenantToUpdate, true),
					},
					{
						label: 'No',
						onClick: () => handleUpdateConfirmed(tenantToUpdate, false),
					},
					{ label: 'Cancel', onClick: handleUpdateCancelled },
				],
			});
		} else {
			await handleUpdateConfirmed(tenantToUpdate);
		}
	};

	const handleUpdateConfirmed = async (tenant, xb) => {
		setDialogState({ open: false });
		await createOrUpdateTenant(tenant, xb);
		setToast(`Saved ${tenant.CompanyName}`);
	};

	const handleUpdateCancelled = () => {
		setDialogState({ open: false });
	};

	const handleDelete = async (tenantToDelete) => {
		setDialogState({
			open: true,
			title: 'Delete Tenant',
			body: `Are you sure you want to delete '${tenantToDelete.CompanyName}'?`,
			buttons: [
				{
					label: 'OK',
					primary: true,
					onClick: () => handleDeleteConfirmed(tenantToDelete),
				},
				{ label: 'Cancel', onClick: handleDeleteCancelled },
			],
		});
	};

	const handleDeleteConfirmed = async (tenantToDelete) => {
		setDialogState({ open: false });

		await deleteTenant(tenantToDelete);
		setToast(`Deleted ${tenantToDelete.CompanyName}`);
	};

	const handleDeleteCancelled = () => {
		setDialogState({ open: false });
	};

	const setNewFilter = debounce((filter) => {
		setSearchTerm(filter);
	}, 250);

	const handleFilterChange = (e) => setNewFilter(e.target.value);

	const handleAdd = () => history.push('/createTenant');

	const handleRefresh = async () => await loadTenants();

	if (!filteredTenants) {
		return null;
	}

	return (
		<Paper>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell
							align="center"
							className={`${classes.tableHead} ${classes.colStart}`}
						>
							Company Key
						</TableCell>
						<TableCell className={`${classes.tableHead} ${classes.colLarge}`}>
							Company Name
						</TableCell>
						<TableCell className={`${classes.tableHead} ${classes.colMedium}`}>
							Login Url
						</TableCell>
						<TableCell className={`${classes.tableHead} ${classes.colMedium}`}>
							Api Url
						</TableCell>
						<TableCell className={`${classes.tableHead} ${classes.colMedium}`}>
							Database Name
						</TableCell>
						<TableCell className={`${classes.tableHead} ${classes.colMedium}`}>
							Environment Name
						</TableCell>
						<TableCell className={`${classes.tableHead} ${classes.colMedium}`}>
							SQL Instance
						</TableCell>
						<TableCell
							align="center"
							className={`${classes.tableHead} ${classes.colSmall}`}
						>
							Users
						</TableCell>
						<TableCell
							align="center"
							className={`${classes.tableHead} ${classes.colSmall}`}
						>
							Mobile Users
						</TableCell>
						<TableCell
							align="center"
							className={`${classes.tableHead} ${classes.colSmall}`}
						>
							Force XB
						</TableCell>
						<TableCell className={`${classes.tableHead} ${classes.colLarge}`}>
							{' '}
							<TextField
								defaultValue={search}
								onChange={handleFilterChange}
								placeholder="Search..."
								style={{ width: '150px' }}
							/>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{filteredTenants
						.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						.map((tenant) => (
							<Tenant
								key={tenant.CompanyKey}
								tenant={tenant}
								onUpdateTenant={handleUpdate}
								onDeleteTenant={handleDelete}
								onEnableCrossBrowser={handleEnableCrossBrowser}
							/>
						))}
				</TableBody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10]}
				component="div"
				count={filteredTenants.length}
				rowsPerPage={rowsPerPage}
				page={page}
				backIconButtonProps={{
					'aria-label': 'Previous Page',
				}}
				nextIconButtonProps={{
					'aria-label': 'Next Page',
				}}
				onChangePage={(event, page) => setPage(page)}
			/>
			<Dialog state={dialogState} />
			<Toast message={toast} onHide={() => setToast()} />
			<Fab onClick={handleRefresh} className={classes.fabRefresh} color="secondary">
				<RefreshIcon />
			</Fab>
			<Fab onClick={handleAdd} className={classes.fabAdd} color="primary">
				<AddIcon />
			</Fab>
		</Paper>
	);
};

export default withStyles(styles)(TenantList);
