import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../../store/reducers';
import App from './App';

const composeEnhancers =
	process.env.NODE_ENV === 'production'
		? compose
		: window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const AppWrapper = () => (
	<Provider store={store}>
		<Router>
			<App />
		</Router>
	</Provider>
);

const getStore = () => store;

export { AppWrapper, getStore };
