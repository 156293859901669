import React from 'react';
import { Redirect } from 'react-router-dom';
import { removeToken } from '../../services/auth';
import useMount from '../../hooks/useMount';

const Logout = ({ logout }) => {
	useMount(() => {
		removeToken();
		logout();
	});

	return <Redirect to="/" />;
};

export default Logout;
