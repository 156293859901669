import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { clearTenantDetail, loadTenant, updateTenantDetail } from './../../store/actions';
import { getTenantDetailForEdit } from '../../store/selectors/tenantDetail';
import TenantDetail from './TenantDetail';

const mapStateToProps = (state) => ({
	tenantDetail: getTenantDetailForEdit(state),
});

const actions = {
	clearTenantDetail,
	loadTenant,
	updateTenantDetail,
};

export default withRouter(connect(mapStateToProps, actions)(TenantDetail));
