import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logout } from '../../store/actions';
import Header from './Header';

const mapStateToProps = ({ googleUser }) => ({
	user: googleUser,
});

const actions = {
	logout,
};

export default withRouter(connect(mapStateToProps, actions)(Header));
