import { withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExploreIcon from '@material-ui/icons/Explore';
import SaveIcon from '@material-ui/icons/Save';
import { Field, Form } from 'react-final-form';
import { Link as RouterLink } from 'react-router-dom';
import Switch from './../shared/Switch';
import TextField from '../shared/TextField';

const required = (value) => (value ? undefined : 'Required');

const styles = (theme) => ({
	colPadding: {
		padding: '0 0 0 10px',
	},
	formatCapital: {
		textTransform: 'capitalize',
	},
});

const Tenant = ({ tenant, onDeleteTenant, onEnableCrossBrowser, onUpdateTenant, classes }) => {
	const formRef = React.createRef();

	const [editing, setEditing] = useState(false);

	const handleUpdateTenant = async (values) => {
		const forcingXB = !tenant.ForceUrl && values.ForceUrl;
		await onUpdateTenant(values, forcingXB);
		setEditing(false);
	};

	const handleDeleteTenant = async () => {
		await onDeleteTenant(tenant);
	};

	const handleEnableCrossBrowser = async () => {
		await onEnableCrossBrowser(tenant);
	};

	const {
		CompanyKey,
		CompanyName,
		Url,
		ApiUrl,
		ForceUrl,
		DatabaseName,
		EnvironmentName,
		SqlInstance,
		Users,
		MobileUsers,
	} = tenant;

	const oneClickXB =
		!ForceUrl && (Url === 'classic.pestpac.com' || Url === 'classic.west.pestpac.com');

	return editing ? (
		<Form
			ref={formRef}
			onSubmit={handleUpdateTenant}
			initialValues={tenant}
			render={({ handleSubmit, invalid }) => (
				<TableRow as="form" onSubmit={handleSubmit} autoComplete="off">
					<TableCell align="center" className={`${classes.colPadding}`}>
						{CompanyKey}
					</TableCell>
					<TableCell className={`${classes.colPadding}`}>
						<Field name="CompanyName" component={TextField} validate={required} />
					</TableCell>
					<TableCell className={`${classes.colPadding}`}>
						<Field name="Url" component={TextField} validate={required} />
					</TableCell>
					<TableCell className={`${classes.colPadding}`}>
						<Field name="ApiUrl" component={TextField} validate={required} />
					</TableCell>
					<TableCell className={`${classes.colPadding}`}>
						<Field name="DatabaseName" component={TextField} disabled={'disabled'} />
					</TableCell>
					<TableCell className={`${classes.colPadding} ${classes.formatCapital}`}>
						<Field name="EnvironmentName" component={TextField} disabled={'disabled'} />
					</TableCell>
					<TableCell className={`${classes.colPadding}`}>
						<Field name="SqlInstance" component={TextField} disabled={'disabled'} />
					</TableCell>
					<TableCell align="center" className={`${classes.colPadding}`}>
						<Field name="Users" component={TextField} disabled={'disabled'} />
					</TableCell>
					<TableCell align="center" className={`${classes.colPadding}`}>
						<Field name="MobileUsers" component={TextField} disabled={'disabled'} />
					</TableCell>
					<TableCell align="center" className={`${classes.colPadding}`}>
						<Field name="ForceUrl" component={Switch} type="checkbox" />
					</TableCell>
					<TableCell>
						<IconButton disabled={invalid} onClick={handleSubmit}>
							<SaveIcon />
						</IconButton>
						<IconButton onClick={() => setEditing(false)}>
							<CancelIcon />
						</IconButton>
					</TableCell>
				</TableRow>
			)}
		/>
	) : (
		<TableRow>
			<TableCell className={`${classes.colPadding}`} align="center">
				<Link component={RouterLink} to={`/tenants/${CompanyKey}`}>
					{CompanyKey}
				</Link>
			</TableCell>
			<TableCell className={`${classes.colPadding}`}>{CompanyName}</TableCell>
			<TableCell className={`${classes.colPadding}`}>{Url}</TableCell>
			<TableCell className={`${classes.colPadding}`}>{ApiUrl}</TableCell>
			<TableCell className={`${classes.colPadding}`}>{DatabaseName}</TableCell>
			<TableCell className={`${classes.colPadding} ${classes.formatCapital}`}>
				{EnvironmentName}
			</TableCell>
			<TableCell className={`${classes.colPadding}`}>{SqlInstance}</TableCell>
			<TableCell className={`${classes.colPadding}`} align="center">
				{Users}
			</TableCell>
			<TableCell className={`${classes.colPadding}`} align="center">
				{MobileUsers}
			</TableCell>
			<TableCell className={`${classes.colPadding}`} align="center">
				{ForceUrl ? 'Yes' : 'No'}
			</TableCell>
			<TableCell>
				<IconButton onClick={() => setEditing(true)}>
					<EditIcon />
				</IconButton>
				<IconButton onClick={handleDeleteTenant}>
					<DeleteIcon />
				</IconButton>
				{oneClickXB && (
					<IconButton onClick={handleEnableCrossBrowser}>
						<ExploreIcon />
					</IconButton>
				)}
			</TableCell>
		</TableRow>
	);
};

export default withStyles(styles)(Tenant);
