import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const BackdropWrapper = styled.div`
	position: fixed;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background-color: rgba(0, 0, 0, 0.3);
	padding: 50px;
	z-index: 5000;
`;

const Backdrop = ({ children }) => <BackdropWrapper>{children}</BackdropWrapper>;

Backdrop.propTypes = {
	children: PropTypes.node,
};

export default Backdrop;
