import React, { useEffect, useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
	Typography,
} from '@material-ui/core';
import useMount from '../../hooks/useMount';

const ViamenteModal = ({
	companyKey,
	onClose,
	updateRouteOp,
	createRouteOp,
	loadRouteOp,
	data,
}) => {
	const [vehicles, setVehicles] = useState();
	const [waypoints, setWaypoints] = useState();
	const [token, setToken] = useState();
	const [tokenExists, setTokenExists] = useState(true);

	useMount(() => {
		loadRouteOp(companyKey);
	});

	useEffect(() => {
		if (data === null) {
			setTokenExists(false);
		} else if (data !== undefined) {
			setVehicles(data.maxVehicles);
			setWaypoints(data.maxWaypoints);
			setToken(data.token);
			setTokenExists(true);
		}
	}, [data]);

	const handleSave = () => {
		updateRouteOp(data.token, companyKey, vehicles, waypoints);
		onClose();
	};

	const handleCreate = () => {
		createRouteOp(companyKey);
	};

	return (
		<Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" open>
			<DialogTitle>Manage RouteOp License Key</DialogTitle>
			<DialogContent>
				{tokenExists ? (
					<Grid container>
						<Grid item xs={12} style={{ marginBottom: '15px' }}>
							<TextField
								value={token}
								label="License Key"
								InputProps={{
									readOnly: true,
								}}
							/>
						</Grid>
						<Grid item xs={12} style={{ marginBottom: '15px' }}>
							<TextField
								value={vehicles}
								name="Vehicles"
								label="Vehicles"
								onChange={(e) => setVehicles(e.target.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								value={waypoints}
								name="Waypoints"
								label="Max Waypoints"
								onChange={(e) => setWaypoints(e.target.value)}
							/>
						</Grid>
					</Grid>
				) : (
					<Typography>No RouteOp License Key was found</Typography>
				)}
			</DialogContent>
			<DialogActions>
				{tokenExists ? (
					<Button onClick={handleSave} variant="contained" color="primary">
						Save
					</Button>
				) : (
					<Button onClick={handleCreate} variant="contained" color="primary">
						Create One
					</Button>
				)}
				<Button onClick={onClose} variant="text" color="primary">
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ViamenteModal;
