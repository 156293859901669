import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';

const SelectRole = ({ value, onChange, disabled }) => {
	return (
		<Select value={value} onChange={onChange} disabled={disabled}>
			<MenuItem value="ReadOnly">Read Only</MenuItem>
			<MenuItem value="ReadWrite">Read/Write</MenuItem>
			<MenuItem value="Admin">Administrator</MenuItem>
		</Select>
	);
};

export default SelectRole;
