import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import React from 'react';
import SelectRole from './SelectRole';

const User = ({
	onCancel,
	onChange,
	onDelete,
	onEdit,
	onSave,
	selected,
	user,
	user: { Email, Role },
}) => {
	const handleChange = ({ target: { value } }) => onChange({ ...user, Role: value });

	const handleDelete = () => onDelete(Email);

	const handleEdit = () => onEdit(user);

	return (
		<TableRow key={Email}>
			<TableCell>{Email}</TableCell>
			{selected ? (
				<>
					<TableCell>
						<SelectRole value={Role} onChange={handleChange} />
					</TableCell>
					<TableCell>
						<IconButton onClick={onSave}>
							<SaveIcon />
						</IconButton>
						<IconButton onClick={onCancel}>
							<CancelIcon />
						</IconButton>
					</TableCell>
				</>
			) : (
				<>
					<TableCell>
						<SelectRole disabled value={Role} />
					</TableCell>
					<TableCell>
						<IconButton onClick={handleEdit}>
							<EditIcon />
						</IconButton>
						<IconButton onClick={handleDelete}>
							<DeleteIcon />
						</IconButton>
					</TableCell>
				</>
			)}
		</TableRow>
	);
};

export default User;
