import styled from 'styled-components';

const styledComponent = styled.img.attrs({ className: 'avatar' })`
	padding: 0;
	margin-right: 10px;
	background: transparent;
	width: 35px;
	height: 35px;
	border: none;
	border-radius: 50%;
	outline: none;
	box-shadow: 0 0 11px 0px #010104;
`;

styledComponent.displayName = 'Avatar';
export default styledComponent;
