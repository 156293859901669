import React from 'react';
import { Field } from 'react-final-form';
import { Checkbox } from 'final-form-material-ui';
import { FormControlLabel, IconButton } from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

const ConfigCheckboxField = ({
	name,
	displayName,
	onLaunchViamente,
	configFieldName,
	disabled,
}) => (
	<>
		<FormControlLabel
			control={
				<Field
					name={`${name}.value`}
					component={Checkbox}
					type="checkbox"
					disabled={disabled}
				/>
			}
			label={displayName}
		/>
		{configFieldName === 'routeopt' && (
			<Field name={`${name}.value`}>
				{({ input: { value } }) => (
					<IconButton disabled={!value} onClick={onLaunchViamente}>
						<VpnKeyIcon />
					</IconButton>
				)}
			</Field>
		)}
	</>
);

export default ConfigCheckboxField;
