import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import BackDrop from './Backdrop';

const FatalWrapper = styled.div`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	display: inline-block;
	background-color: #fff;
	border-radius: 5px;
	margin: 0px auto;
	padding: 30px;
	white-space: normal;
`;

const FatalError = ({ show, message }) => {
	if (!show) {
		return null;
	}
	return (
		<BackDrop>
			<FatalWrapper>
				<Typography>We have encountered a problem within the application.</Typography>
				<pre>{message}</pre>
				<Typography>Please refresh your page and try again.</Typography>
			</FatalWrapper>
		</BackDrop>
	);
};

FatalError.propTypes = {
	show: PropTypes.bool,
	message: PropTypes.string,
};

export default FatalError;
