import React, { useState } from 'react';
import User from './User';

const emptyUser = { Email: '', Role: 'ReadOnly' };

const UserListTable = (props) => {
	const { users, onUpdateUser, onDeleteUser } = props;

	const [selectedUser, setSelectedUser] = useState(emptyUser);

	const handleSave = async () => {
		await onUpdateUser(selectedUser);
		setSelectedUser(emptyUser);
	};

	const handleCancel = () => setSelectedUser(emptyUser);

	const handleEdit = ({ Email, Role }) => setSelectedUser({ Email, Role });

	const handleDelete = async (email) => {
		await onDeleteUser(email);
	};

	return (
		<>
			{users.map((user) => {
				const selected = selectedUser.Email === user.Email;
				return (
					<User
						key={user.Email}
						user={selected ? selectedUser : user}
						selected={selected}
						onCancel={handleCancel}
						onChange={setSelectedUser}
						onSave={handleSave}
						onEdit={handleEdit}
						onDelete={handleDelete}
					/>
				);
			})}
		</>
	);
};

export default UserListTable;
