import React, { useState, useEffect, useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { Checkbox } from 'final-form-material-ui';
import {
	Button,
	FormControlLabel,
	Grid,
	FormControl,
	InputLabel,
	FormHelperText,
	Select,
	MenuItem,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TextField from '../shared/TextField';

const styles = (theme) => ({
	paper: {
		width: '350px',
	},
	form: {
		padding: '15px 15px 15px 15px',
		'& input': {
			width: '300px',
		},
	},
	centered: {
		textAlign: 'center',
	},
	chip: {
		margin: '15px 5px 0 5px',
	},
	button: {
		float: 'right',
		marginLeft: '10px',
	},
	formControl: {
		minWidth: '120px',
	},
	environment: {
		margin: '10px 0 20px',
	},
});

const validate = (values) => {
	if (!values.CompanyKey || !values.CompanyName || !values.Url || !values.ApiUrl) {
		return 'All fields are required';
	}
};

const defaultValues = {
	ForceUrl: true,
};

const CreateTenantRecord = ({ envs, history, classes, onCreateTenant }) => {
	const [environment, setEnvironment] = useState('');
	const formRef = React.createRef();

	const handleChangeEnvironment = ({ target }) => setEnvironment(target.value);
	const handleCancel = () => history.push('/tenants');

	const handleSubmit = async (values) => {
		await onCreateTenant(values);
		history.push('/tenants');
	};

	const prefillUrls = useCallback(
		(env) => {
			formRef.current.form.change('Url', env ? env.AppUrl : '');
			formRef.current.form.change('ApiUrl', env ? env.ApiUrl : '');
		},
		[formRef],
	);

	useEffect(() => {
		prefillUrls(environment);
	}, [environment, prefillUrls]);

	return (
		<Form
			ref={formRef}
			onSubmit={handleSubmit}
			validate={validate}
			initialValues={defaultValues}
			render={({ handleSubmit, form, invalid, pristine }) => (
				<form onSubmit={handleSubmit} autoComplete="off">
					<Grid item xs={12}>
						<Field
							name="CompanyKey"
							label="Company Key"
							component={TextField}
							required
						/>
					</Grid>
					<Grid item xs={12}>
						<Field
							name="CompanyName"
							label="Company Name"
							component={TextField}
							required
						/>
					</Grid>

					<Grid item xs={12} className={classes.environment}>
						<FormControl className={classes.formControl}>
							<InputLabel htmlFor="environment">Environment</InputLabel>
							<Select
								value={environment}
								onChange={handleChangeEnvironment}
								name="environment"
								className={classes.selectEmpty}
							>
								<MenuItem value="" key="env-none">
									<em>None</em>
								</MenuItem>
								{envs.map((env) => (
									<MenuItem value={env} key={`env-${env.name}`}>
										{env.Name}
									</MenuItem>
								))}
							</Select>
							<FormHelperText>Auto-fill environment URLs</FormHelperText>
						</FormControl>
					</Grid>

					<Grid item xs={12}>
						<Field name="Url" label="Login Url" component={TextField} required />
					</Grid>
					<Grid item xs={12}>
						<Field name="ApiUrl" label="API Url" component={TextField} required />
					</Grid>
					<Grid item xs={12}>
						<FormControlLabel
							label="Force Cross-Browser"
							control={<Field name="ForceUrl" component={Checkbox} type="checkbox" />}
						/>
					</Grid>

					<Grid item xs={12}>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							className={classes.button}
							disabled={pristine || invalid}
						>
							Add
						</Button>
						<Button
							color="secondary"
							type="button"
							className={classes.button}
							onClick={handleCancel}
						>
							Cancel
						</Button>
					</Grid>
				</form>
			)}
		/>
	);
};

export default withStyles(styles)(CreateTenantRecord);
