import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { loadMessage, updateMessage } from '../../store/actions';
import SystemMessage from './SystemMessage';

const mapStateToProps = ({ message }) => ({
	message,
});

const mapDispatchToProps = {
	loadMessage,
	updateMessage,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SystemMessage));
