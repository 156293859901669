import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loadPortalAdmins, loadPortalUrls } from '../../store/actions';
import PortalUrlList from './PortalUrlList';

const mapStateToProps = (state) => ({
	urls: state.portalUrls,
});

const mapDispatchToProps = {
	loadPortalUrls,
	loadPortalAdmins,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalUrlList));
