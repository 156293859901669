import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';

const SelectType = ({ value, onChange, disabled }) => {
	return (
		<Select value={value} onChange={onChange} disabled={disabled}>
			<MenuItem value="None">None</MenuItem>
			<MenuItem value="All">All</MenuItem>
			<MenuItem value="Live">Live</MenuItem>
			<MenuItem value="Test">Test</MenuItem>
		</Select>
	);
};

export default SelectType;
