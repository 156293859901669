import React, { Fragment, useState, useEffect } from 'react';
import arrayMutators from 'final-form-arrays';
import { Field, Form, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import {
	Fab,
	Grid,
	Paper,
	TextField as PlainTextField,
	Typography,
	withStyles,
	FormHelperText,
} from '@material-ui/core';

import ViamenteModal from '../ViamenteModal';
import ConfigCheckboxField from './ConfigCheckboxField';
import ConfigTextField from './ConfigTextField';
import TextField from '../shared/TextField';
import Dialog from './../shared/Dialog';
import useMount from '../../hooks/useMount';
import { parseHiddenFields } from '../../config/hiddenFields';

const styles = () => ({
	paper: {
		width: '600px',
	},
	form: {
		padding: '15px 15px 15px 15px',
	},
	input: {
		width: '400px',
		marginTop: '10px',
		marginBottom: '10px',
	},
	button: {
		float: 'right',
		marginLeft: '10px',
	},
	fabSave: {
		position: 'fixed',
		bottom: '16px',
		right: '16px',
	},
	fabBack: {
		position: 'fixed',
		bottom: '16px',
		right: '84px',
	},
	hide: {
		display: 'none',
	},
});

const required = (value) => (value || value === 0 || value === '0' ? undefined : 'Required');

const TenantDetail = ({
	tenantDetail = {},
	classes,
	clearTenantDetail,
	history,
	loadTenant,
	match,
	updateTenantDetail,
}) => {
	const companyKey = match.params.id;

	const [data, setData] = useState(tenantDetail);
	const [open, setOpen] = useState(false);
	const [dialogState, setDialogState] = useState();
	const [hiddenFields, setHiddenFields] = useState([]);

	useMount(() => {
		loadTenant(companyKey);
	});

	useEffect(() => {
		setData(tenantDetail);
	}, [tenantDetail]);

	const updateHiddenFields = (values) => setHiddenFields(parseHiddenFields(values));

	const handleCancel = () => {
		clearTenantDetail();
		history.push('/tenants');
	};

	const handleCloseViamente = () => setOpen(false);

	const handleSave = (values) => {
		const oldRouteOp = data.config.find((c) => c.name === 'routeopt');
		const newRouteOp = values.config.find((c) => c.name === 'routeopt');

		if (oldRouteOp.value === true && newRouteOp.value === false) {
			setDialogState({
				open: true,
				title: 'Disabling RouteOp',
				body:
					'You are turning off RouteOp.  All constraints will be cleared from the database.  Continue?',
				buttons: [
					{
						label: 'Cancel',
						primary: false,
						onClick: () => setDialogState({ open: false }),
					},
					{
						label: 'OK',
						primary: true,
						onClick: () => {
							setDialogState({ open: false });
							continueSave(values, true);
						},
					},
				],
			});
		} else {
			continueSave(values, false);
		}
	};

	const continueSave = async (values, disableRouteOp) => {
		await updateTenantDetail({ ...values, disableRouteOp });
		clearTenantDetail();
		history.push('/tenants');
	};

	const handleLaunchViamente = async () => {
		setOpen(true);
	};

	const validate = () => {
		return undefined;
	};

	if (!data) {
		return null;
	}

	const { isPsub } = data;

	const getFieldDisabled = (configFieldName) => {
		switch (configFieldName) {
			case 'UsePowerBI':
				return !isPsub;
			case 'UseSmartTraps':
				// don't disable if it's enabled already
				const useSmartTrapsConfig = data.config.find(
					(c) => c.name.toLowerCase() === configFieldName.toLowerCase(),
				);
				return (
					!(data.email && data.email.length > 0) &&
					!(useSmartTrapsConfig && useSmartTrapsConfig.value)
				);
			default:
				return false;
		}
	};

	const getFieldDisplay = (configFieldName, displayName) => {
		switch (configFieldName) {
			case 'UsePowerBI':
				return !isPsub
					? `${displayName} - Contact Engineering To Enable This Feature`
					: displayName;
			case 'UseSmartTraps':
				const useSmartTrapsConfig = data.config.find(
					(c) => c.name.toLowerCase() === configFieldName.toLowerCase(),
				);
				return !(data.email && data.email.length > 0) &&
					!(useSmartTrapsConfig && useSmartTrapsConfig.value)
					? `${displayName} - Company Email is Required`
					: displayName;
			default:
				return displayName;
		}
	};

	return (
		<>
			<Form
				onSubmit={handleSave}
				validate={validate}
				mutators={{ ...arrayMutators }}
				initialValues={data}
				render={({ handleSubmit, pristine, invalid }) => (
					<>
						<form onSubmit={handleSubmit} autoComplete="off">
							<Paper className={classes.paper}>
								<Grid container className={classes.form}>
									<Grid item xs={12}>
										<Typography>
											<b>{data.companyName}</b>
											<br />
											{data.address}
											<br />
											{data.address2}
											{data.address2 && <br />}
											{data.city}, {data.state} {data.zip}
											<br />
											{data.phone}
										</Typography>
										<FormHelperText>Email</FormHelperText>
										<Typography style={{ display: 'inline-block' }}>
											{data.email}
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<PlainTextField
											value={data.companyKey}
											className={classes.input}
											label="Company Key"
											InputProps={{
												readOnly: true,
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<PlainTextField
											value={data.sqlInstance}
											className={classes.input}
											label="SQL Instance"
											InputProps={{
												readOnly: true,
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<PlainTextField
											value={data.databaseName}
											className={classes.input}
											label="Database Name"
											InputProps={{
												readOnly: true,
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<Field
											name="adminPassword"
											component={TextField}
											className={classes.input}
											label="Admin Password"
											validate={
												process.env.NODE_ENV === 'production'
													? required
													: undefined
											}
										/>
									</Grid>
									<Grid item xs={12}>
										<Field
											name="expirationDate"
											component={TextField}
											className={classes.input}
											type="date"
											label="Expiration Date"
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</Grid>
									<FieldArray name="config">
										{({ fields }) =>
											fields.map((name, i) => {
												const {
													name: configFieldName,
													fieldType,
													displayName,
												} = data.config[i];
												return (
													<Fragment key={name}>
														<Grid
															item
															xs={12}
															style={
																hiddenFields.includes(name)
																	? { display: 'none' }
																	: {}
															}
														>
															{fieldType === 'Checkbox' ? (
																<ConfigCheckboxField
																	name={name}
																	hiddenFields={hiddenFields}
																	configFieldName={
																		configFieldName
																	}
																	displayName={getFieldDisplay(
																		configFieldName,
																		displayName,
																	)}
																	onLaunchViamente={
																		handleLaunchViamente
																	}
																	disabled={getFieldDisabled(
																		configFieldName,
																	)}
																/>
															) : (
																<ConfigTextField
																	name={name}
																	hiddenFields={hiddenFields}
																	configFieldName={
																		configFieldName
																	}
																	displayName={displayName}
																	fieldType={fieldType}
																	className={classes.input}
																/>
															)}
														</Grid>
													</Fragment>
												);
											})
										}
									</FieldArray>
								</Grid>
								<Fab
									onClick={handleCancel}
									className={classes.fabBack}
									color="secondary"
								>
									<ArrowBackIcon />
								</Fab>
								<Fab
									onClick={handleSubmit}
									className={classes.fabSave}
									disabled={pristine || invalid}
									color="primary"
								>
									<SaveIcon />
								</Fab>
							</Paper>
						</form>
						<FormSpy subscription={{ values: true }} onChange={updateHiddenFields} />
					</>
				)}
			/>
			{open && <ViamenteModal companyKey={companyKey} onClose={handleCloseViamente} />}
			<Dialog state={dialogState} />
		</>
	);
};

export default withStyles(styles)(TenantDetail);
