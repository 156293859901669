import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const Logs = ({ logs, searchLogs }) => {
	const currDate = new Date();

	const [terms, setTerms] = useState('');
	const [timedate, setDate] = useState(currDate.toISOString().substr(0, 16)); //format appropriately for the TextField below

	const handleClick = (evt) => {
		const fetchLogs = async () =>
			await searchLogs({ pattern: `${terms}`, start_time: Date.parse(`${timedate}+00:00`) });
		fetchLogs();
	};

	if (!logs) return null;

	return (
		<>
			<TextField
				defaultValue={timedate}
				onChange={(e) => setDate(e.target.value)}
				id="datetime-local"
				label="Oldest log timestamp - GMT"
				type="datetime-local"
				InputLabelProps={{
					shrink: true,
				}}
				style={{
					marginLeft: 10,
					marginTop: 20,
				}}
			/>
			<TextField
				value={terms}
				onChange={(e) => setTerms(e.target.value)}
				id="outlined-with-placeholder"
				label="Search"
				placeholder="use a dash (-) to exclude a term"
				margin="normal"
				variant="outlined"
				style={{
					marginLeft: 10,
					width: 500,
				}}
			/>
			<Button
				onClick={handleClick}
				variant="contained"
				style={{
					marginLeft: 10,
					marginTop: 25,
				}}
			>
				Search
			</Button>
			{logs.map((l) => (
				<pre key={l + 1}>{l}</pre>
			))}
		</>
	);
};

export default Logs;
