import React, { useEffect, useState, useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { Radio, Select } from 'final-form-material-ui';
import { Button, FormControlLabel, Grid, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TextField from '../shared/TextField';
import { OnChange } from 'react-final-form-listeners';

const styles = (_) => ({
	paper: {
		width: '600px',
	},
	form: {
		padding: '15px 15px 15px 15px',
		'& input': {
			width: '550px',
		},
	},
	button: {
		float: 'right',
		marginLeft: '10px',
	},
	formControl: {
		minWidth: '120px',
	},
	select: {
		minWidth: '200px',
	},
});

const required = (value) => (value ? undefined : 'Required');

const numRequired = (value) => (Number.isFinite(value) ? undefined : 'Required');

const isValidFormat = (key) =>
	/^[\d]{6}$/.test(key) ? undefined : 'A valid, 6 digit company key is required';

const isUnique = (value, keys) => (!keys.includes(value) ? undefined : 'Key must be unique');

const validateForm = ({ Environment, BrandingId }) => {
	if (!Environment) {
		return { Environment: 'Required' };
	}
	if (!BrandingId) {
		return 'Default Values are Required';
	}
};

const defaultValues = {
	IsTestDatabase: 'false',
	UserLicenses: 1,
	MobileLicenses: 0,
	CompanyKey: '',
};

const CreateTenant = ({
	allKeys,
	history,
	companyKey,
	envs,
	classes,
	onCreateTenant,
	getBrandings,
	getUniqueCompanyKey,
}) => {
	const handleCancel = () => history.push('/tenants');
	const [brandings, setBrandings] = useState([]);
	const formRef = React.createRef();

	useEffect(() => {
		formRef.current.form.change('CompanyKey', companyKey.toString());
	}, [companyKey]);

	const handleSelectEnvironment = async (e) => {
		setBrandings(await getBrandings(e));
	};

	const handleSubmit = async (values) => {
		await onCreateTenant(values);
		history.push('/tenants');
	};

	useEffect(() => {
		getUniqueCompanyKey();
	}, []);

	const validateCompanyKey = (value = '') =>
		required(value) || isValidFormat(value) || isUnique(value, allKeys);

	const exists = brandings.some((v) => v.brandingId === 1);

	const prefillUrls = useCallback(() => {
		formRef.current.form.change('BrandingId', brandings.length > 0 ? (exists ? 1 : '') : '');
	}, [formRef]);

	useEffect(() => {
		prefillUrls();
	}, [prefillUrls]);

	return (
		<Form
			onSubmit={handleSubmit}
			ref={formRef}
			validate={validateForm}
			initialValues={defaultValues}
			render={({ handleSubmit, invalid, pristine }) => (
				<form onSubmit={handleSubmit} autoComplete="off">
					<Grid item xs={12}>
						<Field
							name="CompanyKey"
							label="Company Key"
							component={TextField}
							validate={validateCompanyKey}
							maxLength={6}
							required
						/>
					</Grid>

					<Grid item xs={12}>
						<Field
							name="CompanyName"
							label="Company Name"
							component={TextField}
							validate={required}
							required
						/>
					</Grid>

					<Grid item xs={12}>
						<Field
							name="AdminPassword"
							label="Admin Password"
							component={TextField}
							validate={required}
							required
						/>
					</Grid>

					<Grid item xs={12}>
						<Field name="Address" label="Address" component={TextField} />
					</Grid>

					<Grid item xs={12}>
						<Field name="Address2" label="Line 2" component={TextField} />
					</Grid>

					<Grid item xs={12}>
						<Field name="City" label="City" component={TextField} />
					</Grid>

					<Grid item xs={12}>
						<Field name="State" label="State" component={TextField} />
					</Grid>

					<Grid item xs={12}>
						<Field name="Zip" label="Zip" component={TextField} />
					</Grid>

					<Grid item xs={12}>
						<Field name="Phone" label="Phone" component={TextField} />
					</Grid>

					<Grid item xs={12}>
						<Field
							name="UserLicenses"
							label="# of Users"
							component={TextField}
							type="number"
							InputProps={{ inputProps: { min: 0 } }}
							parse={(v) => parseInt(v)}
							validate={numRequired}
							required
						/>
					</Grid>

					<Grid item xs={12}>
						<Field
							name="MobileLicenses"
							label="# of Mobile"
							component={TextField}
							type="number"
							InputProps={{ inputProps: { min: 0 } }}
							parse={(v) => parseInt(v)}
							validate={numRequired}
							required
						/>
					</Grid>

					<Grid item xs={12}>
						<Field
							name="ExpirationDate"
							label="Expiration Date"
							component={TextField}
							type="date"
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Grid>

					<Grid item xs={12}>
						<Field
							name="Environment"
							label="Environment *"
							component={Select}
							className={classes.select}
							required
						>
							<OnChange name="Environment">
								{(value, previous) => {
									handleSelectEnvironment(value);
								}}
							</OnChange>
							{envs.map((e) => (
								<MenuItem key={e.Name} value={e.Name}>
									{e.Name}
								</MenuItem>
							))}
						</Field>
					</Grid>

					<Grid item xs={12}>
						<FormControlLabel
							label="Live"
							control={
								<Field
									name="IsTestDatabase"
									component={Radio}
									type="radio"
									value="false"
								/>
							}
						/>
						<FormControlLabel
							label="Test"
							control={
								<Field
									name="IsTestDatabase"
									component={Radio}
									type="radio"
									value="true"
								/>
							}
						/>
					</Grid>
					<Grid item xs={3}>
						<Field
							name="BrandingId"
							label="Default Values *"
							component={Select}
							className={classes.select}
							required
						>
							{brandings?.map((e) => (
								<MenuItem key={e.description} value={e.brandingId}>
									{e.description}
								</MenuItem>
							))}
						</Field>
					</Grid>
					<Grid item xs={12}>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							className={classes.button}
							disabled={pristine || invalid}
						>
							Create Database
						</Button>
						<Button
							color="secondary"
							type="button"
							className={classes.button}
							onClick={handleCancel}
						>
							Cancel
						</Button>
					</Grid>
				</form>
			)}
		/>
	);
};

export default withStyles(styles)(CreateTenant);
