export const FATAL_ERROR = 'FATAL_ERROR';
export const PENDING_ACTION_COMPLETED = 'PENDING_ACTION_COMPLETED';
export const PENDING_ACTION_STARTED = 'PENDING_ACTION_STARTED';

export const ENVS_LOADED = 'ENVS_LOADED';
export const BRANDS_LOADED = 'BRANDS_LOADED';

export const MESSAGE_LOADED = 'MESSAGE_LOADED';
export const MESSAGE_UPDATED = 'MESSAGE_UPDATED';

export const TENANTS_LOADED = 'TENANTS_LOADED';
export const TENANT_DETAIL_LOADED = 'TENANT_DETAIL_LOADED';
export const TENANT_DETAIL_CLEARED = 'TENANT_DETAIL_CLEARED';
export const TENANT_UPDATED = 'TENANT_UPDATED';
export const TENANT_DELETED = 'TENANT_DELETED';
export const TENANT_SEARCH_SET = 'TENANT_SEARCH_SET';

export const ROUTEOP_DETAIL_LOADED = 'ROUTEOP_DETAIL_LOADED';

export const URLS_LOADED = 'URLS_LOADED';
export const URL_UPDATED = 'URL_UPDATED';
export const URL_DELETED = 'URL_DELETED';

export const PORTALURLS_LOADED = 'PORTALURLS_LOADED';
export const PORTALADMINS_LOADED = 'PORTALADMINS_LOADED';

export const USERS_LOADED = 'USERS_LOADED';
export const USER_UPDATED = 'USER_UPDATED';
export const USER_DELETED = 'USER_DELETED';

export const SQLINSTANCES_LOADED = 'SQLINSTANCES_LOADED';
export const SQLINSTANCE_UPDATED = 'SQLINSTANCE_UPDATED';

export const REQUEST_TOKEN = 'REQUEST_TOKEN';
export const REQUEST_TOKEN_SUCCESS = 'REQUEST_TOKEN_SUCCESS';
export const REQUEST_TOKEN_FAIL = 'REQUEST_TOKEN_FAIL';

export const LOGS_LOADED = 'LOGS_LOADED';

export const GOOGLE_USER_UPDATED = 'GOOGLE_USER_UPDATED';

export const LOGOUT = 'LOGOUT';

export const COMPANYKEY_LOADED = 'COMPANYKEY_LOADED';
