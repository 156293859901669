import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import React, { useState } from 'react';
import Dialog from '../shared/Dialog';
import Toast from '../shared/Toast';
import UrlListTable from './UrlListTable';
import useMount from '../../hooks/useMount';

const emptyUrl = { Url: '', CompanyKey: '' };

const UrlList = ({ urls, loadUrls, updateUrl, deleteUrl }) => {
	const [newUrl, setNewUrl] = useState(emptyUrl);
	const [toast, setToast] = useState();
	const [dialogState, setDialogState] = useState();

	useMount(() => {
		const fetchUrls = async () => await loadUrls();
		fetchUrls();
	});

	const handleAddUrl = async () => {
		if (urls.some((u) => u.Url === newUrl.Url)) {
			setDialogState({
				open: true,
				title: 'Duplicate Url',
				body: `Url '${newUrl.Url}' is already in use`,
				buttons: [{ label: 'OK', onClick: () => setDialogState({ open: false }) }],
			});
			return;
		}

		await handleUpdateUrl(newUrl);
		setToast(`Added ${newUrl.Url}`);
		setNewUrl(emptyUrl);
	};

	const handleUpdateUrl = async (url) => {
		await updateUrl(url);
	};

	const handleDeleteUrl = (url) => {
		setDialogState({
			open: true,
			title: 'Delete Url',
			body: `Are you sure you want to delete '${url}'?`,
			buttons: [
				{ label: 'OK', primary: true, onClick: () => handleDeleteUrlConfirmed(url) },
				{ label: 'Cancel', onClick: handleDeleteUrlCancelled },
			],
		});
	};

	const handleDeleteUrlConfirmed = async (url) => {
		setDialogState({ open: false });
		await deleteUrl(url);
		setToast(`Deleted ${url}`);
	};

	const handleDeleteUrlCancelled = () => {
		setDialogState({ open: false });
	};

	const handleChangeUrl = ({ target: { value: Url } }) => setNewUrl({ ...newUrl, Url });

	const handleChangeKey = ({ target: { value: CompanyKey } }) =>
		setNewUrl({ ...newUrl, CompanyKey });

	if (!urls) {
		return null;
	}

	return (
		<Paper square style={{ maxWidth: '1000px' }}>
			<Table>
				<TableBody>
					<TableRow>
						<TableCell>
							<TextField
								placeholder="Sales Assistant URL"
								value={newUrl.Url}
								onChange={handleChangeUrl}
								style={{ width: '400px' }}
							/>
						</TableCell>
						<TableCell>
							<TextField
								placeholder="Company Key"
								value={newUrl.CompanyKey}
								onChange={handleChangeKey}
							/>
						</TableCell>
						<TableCell>
							<IconButton
								disabled={!newUrl.CompanyKey || !newUrl.Url}
								onClick={handleAddUrl}
							>
								<AddIcon />
							</IconButton>
						</TableCell>
					</TableRow>
					<UrlListTable
						urls={urls}
						onUpdateUrl={handleUpdateUrl}
						onDeleteUrl={handleDeleteUrl}
					/>
				</TableBody>
			</Table>
			<Dialog state={dialogState} />
			<Toast message={toast} onHide={() => setToast()} />
		</Paper>
	);
};

export default UrlList;
