import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { getStore } from '../App';
import FatalError from './FatalError';
import Loader from './Loader';

export default class Watcher extends PureComponent {
	constructor(props, context) {
		super(props, context);

		this.state = {
			showLoader: false,
			showFatalError: false,
			errorMessage: null,
		};

		const store = getStore();

		store.subscribe(() => {
			const { fatalError, pending } = store.getState();

			const showFatalError = fatalError !== null;
			const showLoader = !showFatalError && pending.length ? true : false;
			const errorMessage = (fatalError && fatalError.message) || JSON.stringify(fatalError);

			this.setState(() => ({
				showLoader,
				showFatalError,
				errorMessage,
			}));
		});
	}

	componentDidUpdate(prevProps, prevState) {
		const { onChange } = this.props;
		const { showLoader, showFatalError } = this.state;

		if (!onChange) {
			return;
		}

		if (showLoader !== prevState.showLoader || showFatalError !== prevState.showFatalError) {
			onChange({ showLoader, showFatalError });
		}
	}

	render() {
		return (
			<>
				<Loader show={this.state.showLoader && !this.props.hideLoader} />
				<FatalError show={this.state.showFatalError} message={this.state.errorMessage} />
			</>
		);
	}
}

Watcher.propTypes = {
	hideLoader: PropTypes.bool,
	onChange: PropTypes.func,
};
