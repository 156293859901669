import React, { useEffect, useState } from 'react';
import {
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	withStyles,
} from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import debounce from 'debounce';
import useMount from '../../hooks/useMount';

const styles = (theme) => ({
	tableHead: {
		backgroundColor: '#fff',
		position: 'sticky',
		top: 0,
		zIndex: 1,
	},
	colSmall: {
		width: '20%',
	},
	colMedium: {
		width: '30%',
	},
	colLarge: {
		width: '50%',
	},
});

const matchesFilter = (record, filter) =>
	record.companyKey.includes(filter) || record.domain.toLowerCase().includes(filter);

const PortalUrlList = ({ urls, classes, loadPortalUrls, loadPortalAdmins }) => {
	const [filter, setFilter] = useState();
	const [filteredRecords, setFilteredRecords] = useState();

	useMount(() => {
		const fetchUrls = async () => await loadPortalUrls();
		fetchUrls();
	});

	useEffect(() => {
		const query = filter && filter.trim().toLowerCase();

		if (query) {
			setFilteredRecords(urls.filter((u) => matchesFilter(u, filter)));
		} else {
			setFilteredRecords(urls);
		}
	}, [urls, filter]);

	const setNewFilter = debounce((filter) => {
		setFilter(filter);
	}, 250);

	const handleFilterChange = (e) => setNewFilter(e.target.value);

	const handleLoadAdmins = async (companyKey) => await loadPortalAdmins(companyKey);

	if (!filteredRecords) {
		return null;
	}

	return (
		<Paper square style={{ maxWidth: '1000px' }}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell className={`${classes.tableHead} ${classes.colSmall}`}>
							Company Key
						</TableCell>
						<TableCell className={`${classes.tableHead} ${classes.colLarge}`}>
							Url{' '}
						</TableCell>
						<TableCell className={`${classes.tableHead} ${classes.colMedium}`}>
							<TextField
								defaultValue={filter}
								onChange={handleFilterChange}
								placeholder="Search..."
								style={{ width: '200px' }}
							/>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{filteredRecords.map(({ domain, companyKey, admins }) => (
						<TableRow key={companyKey}>
							<TableCell>{companyKey}</TableCell>
							<TableCell>{domain}</TableCell>
							<TableCell>
								{admins ? (
									admins.toString().replace(',', ', ')
								) : (
									<IconButton onClick={() => handleLoadAdmins(companyKey)}>
										<CloudDownloadIcon />
									</IconButton>
								)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Paper>
	);
};

export default withStyles(styles)(PortalUrlList);
