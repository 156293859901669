import List from '@material-ui/core/List';
import React from 'react';
import SideBarLink from './SideBarLink';

const SideBar = ({ user }) => (
	<List>
		<SideBarLink to="/tenants">Tenants</SideBarLink>
		<SideBarLink to="/saUrls">Sales Assistant URLs</SideBarLink>
		<SideBarLink to="/portalUrls">CustomerConnect URLs</SideBarLink>
		<SideBarLink to="/systemMessage" requiredRole="ReadWrite">
			System Message
		</SideBarLink>
		<SideBarLink to="/sqlInstances" requiredRole="Admin">
			SQL Instances
		</SideBarLink>
		<SideBarLink to="/users" requiredRole="Admin">
			Users
		</SideBarLink>
		<SideBarLink to="/logs" requiredRole="Admin">
			Logs
		</SideBarLink>
	</List>
);

export default SideBar;
