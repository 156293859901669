import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { searchLogs } from '../../store/actions';
import Logs from './Logs';

const mapStateToProps = (state) => ({
	logs: state.logs,
});

const mapDispatchToProps = {
	searchLogs,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logs));
