import {
	ENVS_LOADED,
	FATAL_ERROR,
	GOOGLE_USER_UPDATED,
	LOGOUT,
	LOGS_LOADED,
	MESSAGE_LOADED,
	PENDING_ACTION_COMPLETED,
	PENDING_ACTION_STARTED,
	PORTALADMINS_LOADED,
	PORTALURLS_LOADED,
	REQUEST_TOKEN_FAIL,
	REQUEST_TOKEN_SUCCESS,
	ROUTEOP_DETAIL_LOADED,
	SQLINSTANCES_LOADED,
	SQLINSTANCE_UPDATED,
	TENANTS_LOADED,
	TENANT_DELETED,
	TENANT_DETAIL_CLEARED,
	TENANT_DETAIL_LOADED,
	TENANT_SEARCH_SET,
	TENANT_UPDATED,
	URLS_LOADED,
	URL_DELETED,
	URL_UPDATED,
	USERS_LOADED,
	USER_DELETED,
	USER_UPDATED,
	BRANDS_LOADED,
	COMPANYKEY_LOADED,
} from '../types';

const initialState = {
	envs: [],
	tenants: [],
	users: [],
	saUrls: [],
	portalUrls: [],
	sqlInstances: [],
	tenantDetail: null,
	message: {},
	googleUser: null,
	authError: null,
	search: '',
	pending: [],
	fatalError: null,
	logs: [],
	routeOp: null,
	brands: [],
	companyKey: '',
};

const sorter = (field) => (a, b) => (a[field].toLowerCase() < b[field].toLowerCase() ? -1 : 1);

const rootReducer = (state = initialState, action) => {
	switch (action.type) {
		case FATAL_ERROR:
			return { ...state, fatalError: action.payload };

		case PENDING_ACTION_STARTED:
			return { ...state, pending: [...state.pending, true] };

		case PENDING_ACTION_COMPLETED:
			return { ...state, pending: [...state.pending.slice(1)] };

		case LOGS_LOADED:
			return { ...state, logs: action.payload };

		case ENVS_LOADED:
			return { ...state, envs: action.payload.sort(sorter('Name')) };

		case BRANDS_LOADED:
			return { ...state, brands: action.payload };

		case COMPANYKEY_LOADED:
			return { ...state, companyKey: action.payload };

		case MESSAGE_LOADED:
			return { ...state, message: action.payload };

		case TENANTS_LOADED:
			return {
				...state,
				tenants: action.payload
					.map((t) => ({ ...t, ForceUrl: t.ForceUrl === 1 }))
					.sort(sorter('CompanyKey')),
			};

		case TENANT_DETAIL_LOADED:
			return {
				...state,
				tenantDetail: action.payload,
			};

		case TENANT_DETAIL_CLEARED:
			return { ...state, tenantDetail: null };

		case ROUTEOP_DETAIL_LOADED:
			return {
				...state,
				routeOp: action.payload,
			};

		case TENANT_UPDATED:
			return {
				...state,
				tenants: [
					...state.tenants.filter((t) => t.CompanyKey !== action.payload.CompanyKey),
					{ ...action.payload, ForceUrl: action.payload.ForceUrl === 1 },
				].sort(sorter('CompanyKey')),
			};

		case TENANT_DELETED:
			return {
				...state,
				tenants: [...state.tenants.filter((t) => t.CompanyKey !== action.payload)],
			};

		case TENANT_SEARCH_SET:
			return {
				...state,
				search: action.payload,
			};

		case USERS_LOADED:
			return { ...state, users: action.payload.sort(sorter('Email')) };

		case USER_UPDATED:
			return {
				...state,
				users: [
					...state.users.filter((u) => u.Email !== action.payload.Email),
					action.payload,
				].sort(sorter('Email')),
			};

		case USER_DELETED:
			return {
				...state,
				users: [...state.users.filter((u) => u.Email !== action.payload)],
			};

		case PORTALURLS_LOADED:
			return { ...state, portalUrls: action.payload.sort(sorter('domain')) };

		case PORTALADMINS_LOADED:
			const temp = state.portalUrls.find(
				({ companyKey }) => companyKey === action.payload.companyKey,
			);
			temp.admins = action.payload.admins;

			return {
				...state,
				portalUrls: [
					...state.portalUrls.filter((u) => u.companyKey !== action.payload.companyKey),
					temp,
				].sort(sorter('domain')),
			};

		case URLS_LOADED:
			return { ...state, saUrls: action.payload.sort(sorter('Url')) };

		case URL_UPDATED:
			return {
				...state,
				saUrls: [
					...state.saUrls.filter((u) => u.Url !== action.payload.Url),
					action.payload,
				].sort(sorter('Url')),
			};

		case URL_DELETED:
			return {
				...state,
				saUrls: [...state.saUrls.filter((u) => u.Url !== action.payload)],
			};

		case SQLINSTANCES_LOADED:
			return { ...state, sqlInstances: action.payload.sort(sorter('sqlInstanceName')) };

		case SQLINSTANCE_UPDATED:
			return {
				...state,
				sqlInstances: [
					...state.sqlInstances.filter(
						(i) => i.sqlInstanceId !== action.payload.sqlInstanceId,
					),
					action.payload,
				].sort(sorter('sqlInstanceName')),
			};

		case GOOGLE_USER_UPDATED:
			return { ...state, googleUser: JSON.parse(action.payload) };

		case REQUEST_TOKEN_FAIL:
			return { ...state, authError: action.payload };

		case REQUEST_TOKEN_SUCCESS:
			return { ...state, authError: null };

		case LOGOUT:
			return initialState;

		default:
			return state;
	}
};

export default rootReducer;
