import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';

const UrlListTable = ({ urls = [], onDeleteUrl }) => {
	const handleDelete = async (url) => {
		await onDeleteUrl(url);
	};

	return (
		<>
			{urls.map(({ Url, CompanyKey }) => (
				<TableRow key={Url}>
					<TableCell>{Url}</TableCell>
					<TableCell>{CompanyKey}</TableCell>
					<TableCell>
						<IconButton onClick={() => handleDelete(Url)}>
							<DeleteIcon />
						</IconButton>
					</TableCell>
				</TableRow>
			))}
		</>
	);
};

export default UrlListTable;
