import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { loadEnvironments, loadTenants } from '../../store/actions';
import CreateTenant from '../CreateTenant';
import PortalUrls from '../PortalUrls';
import Watcher from '../shared/Watcher';
import SideBar from '../SideBar';
import SqlInstanceList from '../SqlInstances';
import TenantDetail from '../TenantDetail';
import TenantList from '../TenantList';
import UserList from '../UserList';
import logo from './../../img/ww-pestpac.png';
import Header from './../Header';
import Logs from './../Logs';
import SalesAssistantUrls from './../SalesAssistantUrls';
import SystemMessage from './../SystemMessage';
import UserLogin from './../UserLogin';

const drawerWidth = 240;

const styles = (theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
	},
	appBarTitle: { flexGrow: 1 },
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	logo: {
		...theme.mixins.toolbar,
		width: `calc(${drawerWidth}px - 2px)`,
		padding: '10px',
	},
	toolbar: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.default,
		padding: theme.spacing.unit * 3,
	},
	login: {
		backgroundColor: theme.palette.background.default,
		width: '100vw',
		height: '100vh',
		textAlign: 'center',
	},
});

const RedirectToTenants = () => <Redirect to="/tenants" />;

const App = ({ classes, user, loadEnvironments, loadTenants }) => {
	useEffect(() => {
		const fetchEnvs = async () => await loadEnvironments();
		const fetchTenants = async () => await loadTenants();

		if (user) {
			fetchTenants();
			fetchEnvs();
		}
	}, [user, loadEnvironments, loadTenants]);
	return user ? (
		<div className={classes.root}>
			<CssBaseline />
			<Header />
			<Drawer
				className={classes.drawer}
				variant="permanent"
				anchor="left"
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				<img src={logo} className={classes.logo} alt="Logo" />
				<Divider />
				<SideBar />
			</Drawer>
			<main className={classes.content}>
				<div className={classes.toolbar} />
				<Switch>
					<Route path="/tenants" component={TenantList} exact />
					<Route path="/tenants/:id" component={TenantDetail} exact />
					<Route path="/createTenant" component={CreateTenant} exact />
					<Route path="/portalUrls" component={PortalUrls} exact />
					<Route path="/saUrls" component={SalesAssistantUrls} exact />
					<Route path="/systemMessage" component={SystemMessage} exact />
					<Route path="/sqlInstances" component={SqlInstanceList} exact />
					<Route path="/users" component={UserList} exact />
					<Route path="/logs" component={Logs} exact />
					<Route component={RedirectToTenants} />
				</Switch>
			</main>
			<Watcher />
		</div>
	) : (
		<main className={classes.login}>
			<CssBaseline />
			<UserLogin />
		</main>
	);
};

const mapStateToProps = ({ googleUser, ajaxError }) => ({
	user: googleUser,
	ajaxError,
});

const mapDispatchToProps = {
	loadEnvironments,
	loadTenants,
};

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(App)));
