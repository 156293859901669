import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import SelectType from './SelectType';

const SqlInstanceListTable = ({ instances, onChange }) => {
	return (
		<TableBody>
			{instances.map(({ sqlInstanceId, sqlInstanceName, sqlInstanceType }) => (
				<TableRow key={sqlInstanceName}>
					<TableCell>{sqlInstanceName}</TableCell>
					<TableCell>
						<SelectType
							value={sqlInstanceType}
							onChange={(e) =>
								onChange({
									sqlInstanceId,
									sqlInstanceName,
									sqlInstanceType: e.target.value,
								})
							}
						/>
					</TableCell>
				</TableRow>
			))}
		</TableBody>
	);
};

export default SqlInstanceListTable;
