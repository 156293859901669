const TOKEN = 'ww/pestpac-privatesite/token';

export const parseJwt = (jwt) => atob(jwt.split('.')[1]);

export const isExpired = (token) => {
	const { exp } = JSON.parse(parseJwt(token));
	return exp * 1000 < Date.now();
};

export const isExpiring = (token) => {
	const { exp } = JSON.parse(parseJwt(token));
	const expirationMs = exp * 1000 - Date.now();
	return expirationMs < 60000;
};

export const getToken = () => {
	const token = localStorage.getItem(TOKEN);
	return token ? JSON.parse(token) : undefined;
};

export const setToken = (token) => {
	if (token) {
		try {
			localStorage.setItem(TOKEN, JSON.stringify(token));
		} catch (e) {
			// ...
		}
	}
};

export const removeToken = () => localStorage.removeItem(TOKEN);

export const userHasRole = (requiredRole) => {
	if (!requiredRole) return true;

	const { role } = JSON.parse(parseJwt(getToken()));

	switch (requiredRole) {
		case 'ReadWrite':
			return role === 'ReadWrite' || role === 'Admin';
		case 'Admin':
			return role === 'Admin';
		default:
			return false;
	}
};
