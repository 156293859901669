import { connect } from 'react-redux';

import { loadRouteOp, updateRouteOp, createRouteOp } from '../../store/actions';
import ViamenteModal from './ViamenteModal';

const mapStateToProps = ({ routeOp }) => ({
	data: routeOp,
});

const actions = {
	loadRouteOp,
	updateRouteOp,
	createRouteOp,
};

export default connect(mapStateToProps, actions)(ViamenteModal);
