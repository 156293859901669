// an array of functions that will be reduced to map all fields that should be hidden
const fieldBehaviors = [
	// hide CollectionsProvider field if smallbiz_collection is unchecked
	({ smallbiz_collection, CollectionsProvider }) => {
		if (!smallbiz_collection || !CollectionsProvider) {
			return;
		}
		const { value } = smallbiz_collection;
		const { name } = CollectionsProvider;
		return value ? undefined : name;
	},
];

// map config to an object and pass it to each entr of fieldBehaviors
export const parseHiddenFields = ({ values }) => {
	const { config: fields } = values;

	const mappedFields = fields.reduce((acc, { name, value }, idx) => {
		acc[name] = {
			value,
			name: `config[${idx}]`,
		};
		return acc;
	}, {});

	return fieldBehaviors.reduce((acc, entry) => {
		const fieldName = entry(mappedFields);

		if (fieldName) {
			acc.push(fieldName);
		}

		return acc;
	}, []);
};
