import React from 'react';
import BaseField from '@material-ui/core/TextField';
import useDebounce from '../../hooks/useDebounce';

const TextField = ({
	input: { name, onChange, value, ...restInput },
	meta,
	maxLength,
	...rest
}) => {
	const showError =
		((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

	return (
		<BaseField
			{...rest}
			name={name}
			helperText={showError ? meta.error || meta.submitError : undefined}
			error={showError}
			inputProps={{ ...restInput, value, maxLength }}
			onChange={onChange}
			value={useDebounce(value, 200)}
		/>
	);
};

export default TextField;
