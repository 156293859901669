import {
	MenuItem,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SqlInstanceListTable from './SqlInstanceListTable';

const SqlInstanceList = ({ envs, instances, loadSqlInstances, updateSqlInstance }) => {
	const [env, setEnv] = useState('');

	useEffect(() => {
		const fetchData = async (env) => await loadSqlInstances(env);
		if (env) {
			fetchData(env);
		}
	}, [env, loadSqlInstances]);

	const handleSelectEnvironment = async (e) => {
		const env = e.target.value;
		setEnv(env);
	};

	const handleEditInstance = async (instance) => {
		await updateSqlInstance(instance, env);
	};

	if (!instances) {
		return null;
	}

	return (
		<>
			<TextField
				id="environment"
				select
				label="Environment"
				value={env}
				onChange={handleSelectEnvironment}
				margin="normal"
				style={{ width: 200 }}
			>
				{envs.map((e) => (
					<MenuItem key={e.Name} value={e.Name}>
						{e.Name}
					</MenuItem>
				))}
			</TextField>
			<Paper square style={{ maxWidth: '1000px' }}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Instance</TableCell>
							<TableCell>Accepts New Databases</TableCell>
						</TableRow>
					</TableHead>
					{env ? (
						<SqlInstanceListTable instances={instances} onChange={handleEditInstance} />
					) : (
						<TableBody>
							<TableRow>
								<TableCell>
									<em>Select an environment above</em>
								</TableCell>
							</TableRow>
						</TableBody>
					)}
				</Table>
			</Paper>
		</>
	);
};

export default SqlInstanceList;
