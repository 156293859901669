import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { requestToken, updateGoogleUser } from '../../store/actions';
import UserLogin from './UserLogin';

const mapStateToProps = ({ googleUser, authError }) => ({
	user: googleUser,
	authError,
});

const actions = {
	requestToken,
	updateGoogleUser,
};

export default withRouter(connect(mapStateToProps, actions)(UserLogin));
