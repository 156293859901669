import cloneDeep from 'lodash.clonedeep';
import { createSelector } from 'reselect';

import { getTenants } from './tenants';

export const getTenantDetail = ({ tenantDetail }) => tenantDetail;

export const getTenantDetailForEdit = createSelector(
	getTenantDetail,
	getTenants,
	(tenantDetail, tenants) => {
		if (!tenantDetail) {
			return null;
		}

		const tenant = tenants.find(({ CompanyKey }) => tenantDetail.companyKey === CompanyKey);

		const detail = cloneDeep(tenantDetail);
		return {
			...detail,
			expirationDate: detail.expirationDate && detail.expirationDate.substr(0, 10),
			isPsub: tenant.ApiUrl.endsWith('pestpac.com'),
		};
	},
);
