import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { deleteUser, loadUsers, updateUser } from '../../store/actions';
import UserList from './UserList';

const mapStateToProps = (state) => ({
	users: state.users,
});

const mapDispatchToProps = {
	loadUsers,
	updateUser,
	deleteUser,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserList));
