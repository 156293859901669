import { AppBar, Button, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import Avatar from './Avatar';
import Logout from './Logout';

const drawerWidth = 240;

const styles = (theme) => ({
	appBar: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
	},
	appBarTitle: { flexGrow: 1 },
	button: {
		color: 'white',
		border: '1px solid white',
	},
});

const Header = ({ classes, user, logout }) => {
	const [loggingOut, setLoggingOut] = useState(false);

	const handleClickLogout = () => {
		setLoggingOut(true);
	};

	return loggingOut ? (
		<Logout logout={logout} />
	) : (
		<AppBar position="fixed" className={classes.appBar}>
			<Toolbar>
				<Typography variant="h6" color="inherit" className={classes.appBarTitle}>
					PestPac Administration
				</Typography>
				<>
					<Avatar src={user.picture} alt="Google user" />
					<Button
						variant="outlined"
						className={classes.button}
						onClick={handleClickLogout}
					>
						Log Out
					</Button>
				</>
			</Toolbar>
		</AppBar>
	);
};

export default withStyles(styles)(Header);
