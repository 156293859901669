import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { userHasRole } from '../../services/auth';

const SideBarLink = (props) => {
	if (!userHasRole(props.requiredRole)) return null;

	const renderLink = (itemProps) => (
		<NavLink to={props.to} activeClassName="selected" exact {...itemProps} />
	);

	return (
		<ListItem button component={renderLink}>
			<ListItemText primary={props.children} />
		</ListItem>
	);
};

export default SideBarLink;
