import axios from 'axios';
import { getStore } from './../components/App';
import {
	fatalErrorOccured,
	pendingActionCompleted,
	pendingActionStarted,
} from './../store/actions';
import { getToken, isExpired, isExpiring, removeToken, setToken } from './auth';

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const refreshToken = async () => {
	const oldToken = getToken();

	if (oldToken && !isExpired(oldToken)) {
		try {
			const {
				data: { token },
			} = await axios.post(`${BASE_URL}/token/refresh`, undefined, {
				headers: { Authorization: `Bearer ${oldToken}` },
			});
			return token;
		} catch (e) {
			// maybe token was bad? - raise error to trigger logout
			throw e;
		}
	}
	throw new Error();
};

const refreshTokenInterceptor = async (config) => {
	const existingToken = getToken();

	if (existingToken && !isExpiring(existingToken)) {
		// everything is good - continue as planned
		return config;
	} else {
		// token was missing or expiring - request new
		try {
			const nextToken = await refreshToken();
			setToken(nextToken);
			setAuthTokenHeader(nextToken);
			return config;
		} catch (e) {
			// something shit the bed - clear token & hard reload
			removeToken();
			window.location.href = '/';
			return e;
		}
	}
};

const errorInterceptor = (client) => (error) => {
	const store = getStore();
	store.dispatch(fatalErrorOccured(error));
	return Promise.reject(error);
};

const apiClient = axios.create({
	baseURL: BASE_URL,
	timeout: 60 * 1000 * 3,
	headers: {
		'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
		'Cache-Control': 'no-cache',
	},
});

apiClient.interceptors.request.use(refreshTokenInterceptor);

apiClient.interceptors.request.use((request) => {
	//	if (request.headers['show-loader'] === true) {
	const store = getStore();
	store.dispatch(pendingActionStarted());
	//	}

	return request;
});

apiClient.interceptors.response.use((response) => {
	//	if (response.config.headers['show-loader'] === true) {
	const store = getStore();
	store.dispatch(pendingActionCompleted());
	//	}

	return response;
});

apiClient.interceptors.response.use((response) => response, errorInterceptor(apiClient));

export const setAuthTokenHeader = (token) => {
	if (token) {
		apiClient.defaults.headers['Authorization'] = `Bearer ${token}`;
	} else {
		delete apiClient.defaults.headers.common['Authorization'];
	}
};

export default apiClient;
