import React, { useState } from 'react';
import { Paper, Typography, FormGroup, FormControlLabel, Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import CreateDatabase from './CreateDatabase';
import CreateTenantRecord from './CreateTenantRecord';
import { viewMode } from './index';

const styles = (_) => ({
	paper: {
		width: '600px',
	},
	form: {
		padding: '15px 15px 15px 15px',
		'& input': {
			width: '400px',
		},
	},
});

const AddTenantWrapper = ({
	allKeys,
	createOrUpdateTenant,
	createTenantDatabase,
	envs,
	companyKey,
	history,
	classes,
	getBrandings,
	getUniqueCompanyKey,
}) => {
	const [mode, setMode] = useState(viewMode.CreateDatabase);

	const handleChangeMode = ({ target }) =>
		setMode(target.checked ? viewMode.CreateTenantOnly : viewMode.CreateDatabase);

	return (
		<>
			<FormGroup row>
				<FormControlLabel
					control={
						<Switch
							checked={mode === viewMode.CreateTenantOnly}
							onChange={handleChangeMode}
							value="mode"
						/>
					}
					label="Tenant Record Only"
				/>
			</FormGroup>
			<Paper className={classes.paper}>
				<Grid container className={classes.form}>
					{mode === viewMode.CreateDatabase && (
						<Grid item xs={12}>
							<Typography variant="h6">Create a New Database</Typography>
							<CreateDatabase
								envs={envs}
								allKeys={allKeys}
								companyKey={companyKey}
								history={history}
								onCreateTenant={createTenantDatabase}
								getBrandings={getBrandings}
								getUniqueCompanyKey={getUniqueCompanyKey}
							/>
						</Grid>
					)}

					{mode === viewMode.CreateTenantOnly && (
						<Grid item xs={12}>
							<Typography variant="h6">Just Add a Tenant Record</Typography>
							<CreateTenantRecord
								envs={envs}
								history={history}
								onCreateTenant={createOrUpdateTenant}
								getBrandings={getBrandings}
							/>
						</Grid>
					)}
				</Grid>
			</Paper>
		</>
	);
};

export default withStyles(styles)(AddTenantWrapper);
